export enum Operator {
  equals = "eq",
  notEquals = "ne",
  greaterThan = "gt",
  greaterThanOrEquals = "ge",
  lessThan = "lt",
  lessThanOrEquals = "le",
  between = "bt",
  startsWith = "startsWith",
  endsWith = "endsWith",
  contains = "contains",
  and = "and",
  or = "or",
  not = "not",
  notIn = "notIn",
}

export class Condition {
  operator: Operator;
  value: any;

  constructor(operator: Operator, value: any) {
    this.operator = operator;
    this.value = Array.isArray(value) ? value.join(",") : value;
  }

  static whereNotIn(value: Array<string>) {
    return new Condition(Operator.notIn, value);
  }
  static where(operator: Operator, value: any) {
    return new Condition(operator, value);
  }

  static whereEquals(value: any) {
    return new Condition(Operator.equals, value);
  }

  static greaterThan(value: string) {
    return new Condition(Operator.greaterThan, value);
  }

  static greaterThanOrEquals(value: string) {
    return new Condition(Operator.greaterThanOrEquals, value);
  }

  static lessThan(value: string) {
    return new Condition(Operator.lessThan, value);
  }

  static lessThanOrEquals(value: string) {
    return new Condition(Operator.lessThanOrEquals, value);
  }

  static startsWith(value: string) {
    return new Condition(Operator.startsWith, value);
  }

  static contains(value: string) {
    return new Condition(Operator.contains, value);
  }

  static whereBetween(value: Array<string>) {
    return new Condition(Operator.between, value);
  }

  static whereIn(value: Array<string>) {
    return new Condition(Operator.equals, value);
  }

  static whereNull() {
    return new Condition(Operator.equals, null);
  }
}

export class Filter {
  field: string;
  condition: Condition;

  constructor(field: string, condition: Condition) {
    this.field = field;
    this.condition = condition;
  }

  static where(field: string, operator: Operator, value: any) {
    return new Filter(field, Condition.where(operator, value));
  }
  static whereNotIn(field: string, value: Array<string>) {
    return new Filter(field, Condition.whereNotIn(value));
  }
  static whereEquals(field: string, value: any) {
    return new Filter(field, Condition.whereEquals(value));
  }

  static startsWith(field: string, value: string) {
    return new Filter(field, Condition.startsWith(value));
  }

  static whereBetween(field: string, value: Array<string>) {
    return new Filter(field, Condition.whereBetween(value));
  }

  static whereIn(field: string, value: Array<string>) {
    return new Filter(field, Condition.whereIn(value));
  }

  static whereNull(field: string) {
    return new Filter(field, Condition.whereNull());
  }
}

export class QueryBuilder extends Map<string, Filter> {
  static where(field: string, operator: Operator, value: any) {
    return new QueryBuilder().where(field, operator, value);
  }

  static whereEquals(field: string, value: any) {
    return new QueryBuilder().whereEquals(field, value);
  }

  where(field: string, operator: Operator, value: any) {
    this.set(field, Filter.where(field, operator, value));
    return this;
  }

  whereEquals(field: string, value: any) {
    return this.where(field, Operator.equals, value);
  }

  addFilter(filter: Filter) {
    this.set(filter.field, filter);
    return this;
  }

  addFilters(filters: Array<Filter>) {
    filters.forEach((filter) => this.addFilter(filter));
    return this;
  }

  removeFilter(filter: Filter) {
    this.delete(filter.field);
    return this;
  }

  build(): URLSearchParams {
    const params = new URLSearchParams();
    this.forEach((filter: Filter, field: string) => {
      params.append(
        "filter[" +
          field +
          "]" +
          (filter.condition.operator != Operator.equals
            ? "[" + filter.condition.operator + "]"
            : ""),
        filter.condition.value
      );
    });
    return params;
  }
}
