
























import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { Product } from "@/interfaces/product";
import { MainProductGrid } from "@/utils/grid/main-product-grid";
import GridPreferencesComponent from "../_shared/grid-components/grid-preferences-component.vue";
import { Stock } from "@/interfaces/stock";
import GridQuantityInfoComponent from "../_shared/grid-components/grid-products/grid-quantity-info-component.vue";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridQuantityComponent, {
  GridQuantityComponentParams,
} from "../_shared/grid-components/grid-products/grid-quantity-component.vue";
import { Filter, Operator } from "@/providers/filter";
import GridColorChipComponent from "../_shared/grid-components/grid-cells/grid-color-chip-component.vue";
import GridCartComponent, {
  GridCartComponentParams,
} from "../_shared/grid-components/grid-products/grid-cart-component.vue";
import GridBoolComponent from "../_shared/grid-components/grid-cells/grid-bool-component.vue";

@Component({
  name: "order-product-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    GridQuantityComponent,
    GridQuantityInfoComponent,
    GridBoolComponent,
    AppIconTextButton,
    GridColorChipComponent,
    CommonSearchComponent,
    GridPreferencesComponent,
    GridCartComponent,
  },
})
export default class OrderMainProductGrid extends MainProductGrid {
  @Prop() rowData: Array<Product>;

  @Prop() cart: Array<Stock>;

  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop({ default: null }) addProductCallback: Function;

  async beforeMount() {
    super.beforeMount();
    this.externalFilters.addFilter(Filter.where("stocks.available", Operator.greaterThan, 0));
    this.externalFilters.addFilter(
      Filter.where("stocks.quality", Operator.notIn, ["BLO", "NC", "NCO"])
    );
  }

  $refs: {
    config: HTMLFormElement;
    preferences: HTMLFormElement;
    productGridFilter: HTMLFormElement;
  };

  columns = [
    ...this.getCommonProductGridField(),
    {
      headerName: this.$t("stock.available"),
      field: "stocks",
      pinned: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      filterFramework: null,
      resizable: false,
      suppressMovable: true,
      cellRendererFramework: "GridQuantityInfoComponent",
      valueGetter: (params: any) => {
        let nbInCart = 0;
        this.cart.forEach((item: Stock) => {
          if (params.data?.code == item.product.code) {
            nbInCart += item.ordered;
          }
        });
        return { stocks: params.data?.stocks, nbInCart };
      },
    },
    {
      headerName: this.$t("form.quantity"),
      field: "quantity",
      pinned: "right",
      sortable: false,
      filterFramework: null,
      resizable: false,
      suppressMovable: true,
      cellRendererFramework: "GridQuantityComponent",
      cellClass: "cell-align-company",
      width: 75,
      minWidth: 75,
      valueGetter: (params: any): GridQuantityComponentParams => {
        let nbInCart = 0;
        let max = 0;
        if (params.data) {
          this.cart.forEach((item: Stock) => {
            if (params.data?.code == item.product.code) {
              nbInCart += item.ordered;
            }
          });
          params.data?.stocks.forEach((stock: Stock) => {
            max += stock.available;
          });
        }
        return { max: max, quantity: nbInCart };
      },
    },
    {
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      cellClass: "cell-align-company",
      sortable: false,
      resizable: false,
      suppressMovable: true,
      filterFramework: null,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      valueGetter(params: any) {
        return { id: params.data?.id, active: [true] };
      },
      cellRendererParams: {
        icons: [{ icon: "mdi-magnify", event: "open-product-detail-modal" }],
      },
    },
    {
      cellRendererFramework: "GridCartComponent",
      pinned: "right",
      field: "cart",
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      cellClass: "cell-align-company",
      sortable: false,
      filterFramework: null,
      resizable: false,
      suppressMovable: true,
      valueGetter(params: any): GridCartComponentParams {
        let result = 0;
        if (params.data && params.context.cart) {
          params.context.cart.forEach((stock: Stock) => {
            if (params.data.id == stock.product.id) {
              result += stock.ordered;
            }
          });
        }
        return { quantity: result, disabled: false };
      },
    },
  ];
}
